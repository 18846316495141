<template>
  <v-container class="fill-height" fluid>
    <v-row align="center" justify="center">
      <v-col cols="12" sm="10" md="8" lg="6">
        <v-card class="elevation-12">
          <v-toolbar color="primary" dark flat>
            <v-toolbar-title>Login form</v-toolbar-title>
            <v-spacer />
          </v-toolbar>
          <v-card-text>
            <v-form @keyup.native.enter="login()">
              <v-text-field
                label="Login"
                name="login"
                v-model="email"
                prepend-icon="person"
                type="text"
              />

              <v-text-field
                v-model="password"
                id="password"
                label="Password"
                name="password"
                prepend-icon="lock"
                type="password"
              />
            </v-form>
            <div
              v-if="wrongDetails"
              class="font-weight-bold red pa-2 white--text"
            >Ihr Password war nicht korrekt. Bitte erneut eingeben.</div>
            <div
              v-if="userNotFound"
              class="font-weight-bold red pa-2 white--text"
            >Ihre E-Mail war nicht korrekt. Bitte überprüfen Sie ihre Eingabe.</div>
            <div
              v-if="userNotActive"
              class="font-weight-bold red pa-2 white--text"
            >Ihr Konto ist nicht aktiv. Bitte wenden Sie sich an ihren Administrator.</div>
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn color="primary" @click="login()">Login</v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      email: "",
      password: "",
      wrongDetails: false,
      userNotFound: false,
      userNotActive: false
    };
  },
  methods: {
    login: function() {
      let email = this.email;
      let password = this.password;
      this.wrongDetails = false;
      this.userNotFound = false;
      this.userNotActive = false;
      let axios = this.$http;
      this.$store
        .dispatch("login", { email, password, axios })
        .then(resp => {
          if (resp.wrongDetails) {
            this.wrongDetails = true;
            this.password = "";
            return;
          }
          if (resp.userNotFound) {
            this.userNotFound = true;
            this.password = "";
            return;
          }
          if (resp.userNotActive) {
            this.userNotActive = true;
            this.password = "";
            return;
          }
          this.$router.push("/");
        })
        .catch(err => {
          console.log(err);
        });
    }
  }
};
</script>
